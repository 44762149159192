<template>
  <div class="OverviewView">
    <UserPanel />
    <ModalContainer
      class="modalContainer"
      name="social-modal" />
  </div>
</template>

<script>
import UserPanel from '../components/UserPanel';
import ModalContainer from '@/components/ModalContainer';

export default {
    components: { UserPanel, ModalContainer }
};
</script>

<style scoped lang="scss">
.OverviewView {
    background-color: white;
    height: 100%;
    overflow-y: auto;
}
</style>
