<template>
  <div class="CreateGroupPopup">
    <div>CreateGroupPopup</div>
    <br>

    <div>
      <input
        id="checkbox"
        v-model="isPrivate"
        type="checkbox"> <label for="checkbox">private</label>
    </div>
    <br>
    <div>
      Name: <input
        id="checkbox"
        v-model.trim="name"
        type="text">
    </div>

    <br>
    <div>
      <Button
        label="Cancel"
        @click="$emit('signal', 'cancel')" />
            &nbsp;
      <Button
        label="Create"
        :disabled="!valid"
        @click="create()" />
    </div>
  </div>
</template>

<script>
import Button from './Button';

export default {
    components: { Button },
    data() {
        return {
            isPrivate: true,
            name: ''
        };
    },
    computed: {
        valid() {
            return !!this.name;
        }
    },
    methods: {
        create() {
            this.$emit('signal', { isPrivate: this.isPrivate, name: this.name });
        }
    }
};
</script>

<style scoped lang="scss">
.CreateGroupPopup {
    padding: 1em;
    border: 1px solid rgba(black, 0.1);
    border-radius: 0.5em;
    background-color: white;
}
</style>
