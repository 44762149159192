<template>
  <div class="UserPanel">
    <div><b>User</b> {{ $store.state.moduleSocial.userId }}</div>
    <br>
    <div>userName: {{ $store.state.moduleSocial.userName }}</div>
    <div>email: {{ $store.state.moduleSocial.email }}</div>

    <br>
    <div>
      <Button
        label="Create new group"
        @click="createGroup()" />
            &nbsp;
      <Button
        label="Refresh"
        @click="refresh()" />
    </div>

    <div class="groups">
      <GroupItem
        v-for="(group, i) of groups"
        :key="i"
        :group="group"
        @click.native="gotoGroupView(group.id)" />
    </div>

    <h2>Group invitations</h2>
    <div>
      <GroupInvitation
        v-for="(groupInvitation, i) of groupInvitations"
        :key="i"
        :group-invitation="groupInvitation" />
    </div>
    <div v-if="groupInvitations.length === 0">
      (No invitations)
    </div>
  </div>
</template>

<script>
import GroupItem from './GroupItem';
import Button from './Button';
import GroupInvitation from './GroupInvitation';
import CreateGroupPopup from './CreateGroupPopup';

export default {
    components: { GroupItem, Button, GroupInvitation },
    computed: {
        groups() {
            return [
                ...this.$store.getters['moduleSocial/privateGroups'],
                ...this.$store.getters['moduleSocial/publicGroups']
            ];
        },
        groupInvitations() {
            return this.$store.state.moduleSocial.groupInvitations;
        }
    },
    mounted() {
        this.$store.dispatch('moduleSocial/refresh');
    },
    methods: {
        async createGroup() {
            const result = await this.$modal
                .open({
                    component: CreateGroupPopup,
                    target: 'social-modal'
                })
                .waitDone();
            if (result === 'cancel') {
                return;
            }

            this.$store.dispatch('moduleSocial/createGroup', result);
        },
        refresh() {
            this.$store.dispatch('moduleSocial/refresh');
        },
        gotoGroupView(groupId) {
            this.$router.push(`/social/group/${groupId}`);
        }
    }
};
</script>

<style scoped lang="scss">
.UserPanel {
    padding: 1em;
}

.GroupItem {
    margin-top: 1em;
}
</style>
