<template>
  <div class="GroupInvitation">
    <div><b>Invitation</b> {{ groupInvitation.id }}</div>
    <br>
    <div>{{ groupInvitation }}</div>

    <br>
    <div>
      <Button
        class="button"
        label="Delete"
        @click="deleteGroupInvitation()" />
            &nbsp;
      <Button
        class="button"
        label="Accept"
        @click="accept()" />
    </div>
  </div>
</template>

<script>
import Button from './Button';

export default {
    components: { Button },
    props: {
        groupInvitation: {
            type: Object,
            required: true
        }
    },
    methods: {
        deleteGroupInvitation() {
            this.$store.dispatch('moduleSocial/deleteGroupInvitation', { groupInvitationId: this.groupInvitation.id });
        },
        accept() {
            this.$store.dispatch('moduleSocial/acceptGroupInvitation', { groupInvitationId: this.groupInvitation.id });
        }
    }
};
</script>

<style scoped lang="scss">
.GroupInvitation {
    padding: 1em;
    border: 1px solid rgba(black, 0.1);
    border-radius: 0.5em;
}
</style>
