<template>
  <div class="GroupItem">
    <div><b>Group</b> {{ group.id }}</div>
    <br>
    <div
      class="accessTag"
      :class="group.private ? 'accessTag--private' : 'accessTag--public'">
      {{ group.private ? 'private' : 'public' }}
    </div>

    <div>name: {{ group.name }}</div>
  </div>
</template>

<script>
export default {
    props: {
        group: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped lang="scss">
.GroupItem {
    position: relative;
    padding: 1em;
    border: 1px solid rgba(black, 0.1);
    border-radius: 0.5em;
}

.accessTag {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    padding: 0.5em 1em;
    border-radius: 2em;
    font-size: 90%;
}
.accessTag--private {
    background-color: #7d398e;
    color: white;
}
.accessTag--public {
    background-color: #528e39;
    color: white;
}
</style>
